import styled from 'styled-components';
import { Bar } from 'react-chartjs-2';
import { Link, useNavigate, useParams, NavLink } from "react-router-dom";
import { RadioButton, Success, LoadingStyle } from "../assets/styledcomponents/StyledComponents.js";
import SidebarListItem from './SidebarListItem.jsx';
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { CurrentUserContext } from "../security/CurrentUserContext.js";
import { useState, useEffect, useContext, useMemo, useRef, forwardRef } from "react";
import SideBarTemplate from './SideBarTemplate.jsx';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
// Helper for formatting numbers

const formatNumber = (value) => {
    const num = Number(value);
    return !isNaN(num) ? num.toLocaleString("en-US", { maximumFractionDigits: 0 }) : value;
};

// API base URL defined in environment variables.
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const AdminTemplate = ({ children }) => {

    // default stuff
    const {
        currentUserState,
        actions: { receiveUserInfoFromServer, receiveUserAuthFromServer, removeCurrentUser }
    } = useContext(CurrentUserContext);
    const navigate = useNavigate();
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleLogout = () => {
        fetch(`${API_BASE_URL}/api/logout`, {
            credentials: "include",
            method: "POST",
            headers: { "Content-Type": "application/json" }
        })
            .then((response) => {
                if (response.ok) {
                    removeCurrentUser();
                    navigate("/");
                } else {
                    throw new Error("Logout failed");
                }
            })
            .catch(console.error);
    };

    const handleMenuToggle = () => {
        setIsMenuOpen((prev) => !prev);
    };

    const toggleSidebar = () => {
        setSidebarVisible((prev) => !prev);
    };
    // ////////////////////////////////////////////////////////////////



    return (

        <>
            <Layout>
                <NavBar>
                    <ToggleButton onClick={toggleSidebar} show={sidebarVisible}>
                        {sidebarVisible ? "Hide" : "Show"}
                    </ToggleButton>

                    <section className="w3l-header-4">
                        <div style={{ display: "flex", justifyContent: "space-between", background: "#b0bcbf" }}>
                            <LogoContainer>
                                <LogoLink to="/">
                                    <LogoImage src="/logo.png" alt="BROWEN Logo" />
                                </LogoLink>
                            </LogoContainer>
                            <MenuButton onClick={handleMenuToggle}>&#9776;</MenuButton>
                            <NavItemsContainer isMenuOpen={isMenuOpen}>
                                <NavItems to="/admin" className="signin">
                                    Admin
                                </NavItems>
                                <NavItems to="/admin/prospectingmap" className="signin">
                                    Prospecting
                                </NavItems>
                                <NavItems to="/admin/instantmessage" className="signin">
                                    Messaging
                                </NavItems>
                                <NavItems to="/profile" className="signin">
                                    Profile
                                </NavItems>
                                {/* Other navigation links */}
                                {currentUserState && currentUserState.user_type === 'superuser' && (
                                    <NavItems to="/admin/activitydashboard">Activity</NavItems>
                                )}
                                <NavItems onClick={handleLogout} className="signin">
                                    Logout
                                </NavItems>
                            </NavItemsContainer>
                        </div>
                    </section>
                </NavBar>
                <SideBarStyled show={sidebarVisible}>
                    
                    {/* <SideBarTemplate>
                        loadingState={loadingState}
                        users={users}
                        otherUser={otherUser}
                        handleOtherUserChange={handleOtherUserChange}
                    </SideBarTemplate> */}

                </SideBarStyled>
                <Content sidebarVisible={sidebarVisible}>

                    {children}

                </Content>
            </Layout>
        </>
    );
};

export default AdminTemplate;

const SideBarStyled = styled.div`
    background-color: #b0bcbf;
    color: white;
    width: 200px;
    padding-left: 20px;
    padding-right: 20px;
    position: fixed;
    top: 61px;
    left: 0;
    bottom: 0;
    overflow-y: auto; /* Enables vertical scrolling */
    overflow-x: hidden; /* Disables horizontal scrolling */
    max-height: calc(100vh - 61px); /* Ensures the sidebar doesn't exceed viewport height */
    transition: transform 0.3s ease-in-out;
    transform: ${({ show }) => (show ? "translateX(0)" : "translateX(-100%)")};
`;

const Content = styled.div`
    margin-left: ${({ sidebarVisible }) => (sidebarVisible ? "200px" : "0")};
    margin-top: 60px;
    padding: 20px;
    overflow-y: scroll;
    height: calc(100vh - 60px);
    transition: margin-left 0.3s ease-in-out;
`;


const NavItems = styled(NavLink)`
    text-decoration: none;
    transition: 100ms;
    color: white;
    align-items: center;
    font-weight: bold;
    justify-content: center;
    margin: 5px;
    font-size: 20px;
    width: 125px;
    text-align: center;
    border-radius: 3px;
    border: 3px solid transparent;
    &:hover {
        border: 3px solid transparent;
        color: grey;
    }
    &:active {
        color: white;
        border: 3px solid transparent;
        background-color: var(--primary-color);
    }
    .active {
        color: var(--primary-color);
    }
    &:disabled,
    &:hover:disabled {
        background-color: #707070;
        opacity: 0.4;
        cursor: not-allowed;
    }
`;


const NavItemsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    @media (max-width: 768px) {
        flex-direction: column;
        display: ${({ isMenuOpen }) => (isMenuOpen ? "flex" : "none")};
        background-color: #b0bcbf;
        position: absolute;
        top: 55px;
        right: 0;
        width: 150px;
        z-index: 9999;
    }
`;


const MenuButton = styled.button`
    background: transparent;
    border: none;
    color: white;
    font-size: 30px;
    display: none;
    @media (max-width: 768px) {
        display: block;
    }
`;


const LogoContainer = styled.div`
    display: flex;
    align-items: center;
`;

const LogoLink = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
`;

const LogoImage = styled.img`
    width: 200px;
    height: auto;
    @media (max-width: 580px) {
        width: 170px;
    }
    @media (max-width: 480px) {
        width: 125px;
    }
`;


const ToggleButton = styled.button`
    position: fixed;
    left: ${({ show }) => (show ? "207px" : "7px")};
    top: 65px;
    z-index: 1010;
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #ef5350;
    color: black;
    border: none;
    border-radius: 5px;
    margin-bottom: 1px;
    cursor: pointer;
    transition: left 0.3s ease-in-out;
    &:hover {
        background-color: #e53935;
    }
`;

const NavBar = styled.div`
    text-align: center;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    background: #b0bcbf;
    padding: 10px 20px;
`;
const Layout = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;
