import './App.css';
import { Routes, Route, useLocation, Navigate} from 'react-router-dom';
import React, { useState, useEffect, useContext } from "react";
import HeaderComponent from './components/HeaderComponent.jsx';
import FooterComponent from './components/FooterComponent.jsx';
import HomeComponent from './components/HomeComponent.jsx';
import PricingComponent from './components/PricingComponent.jsx';
import MoveTopComponent from './components/MoveTopComponent.jsx';
import LoginComponent from './components/LoginComponent.jsx';
import RegisterComponent from './components/RegisterComponent.jsx';
import ErrorComponent from './components/ErrorComponent.jsx';
import AboutComponent from './components/AboutComponent.jsx';
import ServicesComponent from './components/ServicesComponent.jsx';
import AdminComponent from './adminpages/Admin';
import ContactComponent from './components/ContactComponenet.jsx';
import PropertyByTypeComponent from './components/PropertyByTypeComponent.jsx';
import PropertyByLocationComponent from './components/PropertyByLocationComponent.jsx';
import PropertyDetailComponent from './components/PropertyDetailComponent.jsx';
import { CurrentUserContext } from "./security/CurrentUserContext.js";
import NewHome from './components/NewHome.jsx';
import Door from './adminpages/DoorComp';
import Cookies from 'js-cookie';
import ProspectingMap from './adminpages/ProspectingMap';
import Chat from "./messaging/components/Chat.js";
import ProtectedRoute from './security/ProtectedRoute.js';
import AdminLayout from './AdminLayout.js';
import PaymentPage from './payments/PaymentPage.jsx';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import ProfilePage from './profile/ProfilePage.jsx'
import Dashboard from './adminpages/DashboardActivity.jsx';

import SuperuserRoute from './security/SuperuserRoute.js';

// Replace with your own Stripe publishable key
const stripePromise = loadStripe('YOUR_PUBLISHABLE_KEY_HERE');

function App() {

	let location = useLocation();
    
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const {
        currentUserState,
        actions: {
            receiveUserInfoFromServer,
            receiveUserAuthFromServer,
            removeCurrentUser,
        },
    } = useContext(CurrentUserContext);

	return (
        <>
            {location.pathname !== "/login" && location.pathname !== "/register" && !location.pathname.startsWith("/admin") && <HeaderComponent />}
            <Routes>
                <Route path="/" element={<NewHome />} />
                <Route path="/login" element={<LoginComponent />} />
                <Route path="/register" element={<RegisterComponent />} />
                <Route path="/about" element={<AboutComponent />} />
                <Route path="/services" element={<ServicesComponent />} />
                <Route path="/contact" element={<ContactComponent />} />
                <Route path="/properties" element={<PropertyByTypeComponent />} />
                <Route path="/properties/location/:location" element={<PropertyByLocationComponent />} />
                <Route path="/properties/:propid/*" element={<PropertyDetailComponent />} />
                {/* <Route path="/properties/:propid" element={<PropertyDetailComponent />} />
                <Route path="/properties/:propid" element={<PropertyDetailComponent />} />
                <Route path="/properties/:propid/:floorid" element={<PropertyDetailComponent />} />
                <Route path="/properties/:propid/:floorid/:unitid" element={<PropertyDetailComponent />} /> */}
                
                <Route path="profile" element={<ProfilePage />} />
                <Route path="/paymentpage" element={<Elements stripe={stripePromise}><PaymentPage /></Elements>}/>

                {/* Nested admin routes */}
                <Route
                    path="/admin"
                    element={
                        <ProtectedRoute>
                            <AdminLayout />
                        </ProtectedRoute>
                    }
                >
                    <Route path=":select_building?" element={<AdminComponent />} />
                    <Route path="door" element={<Door />} />
                    <Route path="prospectingmap/:select_list?/:unit_id?" element={<ProspectingMap />} />
                    <Route path="instantmessage" element={<Chat />} />
                    {/* Only superusers can access this route */}
                    <Route
                        path="activitydashboard"
                        element={
                            <SuperuserRoute>
                                <Dashboard />
                            </SuperuserRoute>
                        }
                    />
                    {/* <Route path="activitydashboard" element={<Dashboard />} />     */}
                </Route>
                {/* <Route
                    path="/admin"
                    element={
                        <ProtectedRoute>
                            <AdminLayout />
                        </ProtectedRoute>
                    }
                >
                    <Route index element={<AdminComponent />} />
                    <Route path=":select_building" element={<AdminComponent />} />
                    <Route path="door" element={<Door />} />
                    <Route path="prospectingmap" element={<ProspectingMap />} />
                    <Route path="prospectingmap/:select_list" element={<ProspectingMap />} />
                    <Route path="prospectingmap/:select_list/:unit_id" element={<ProspectingMap />} />
                    <Route path="instantmessage" element={<Chat />} />
                </Route> */}

                <Route path="/*" element={<ErrorComponent />} />
            </Routes>
            {location.pathname !== "/login" && location.pathname !== "/register" && !location.pathname.startsWith("/admin") && <FooterComponent />}
            <MoveTopComponent />
        </>
    );
}

export default App;
