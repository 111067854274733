import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Bar, Line } from 'react-chartjs-2';
import AdminTemplate from './AdminTemplate';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    TimeScale,
    LineElement,
    BarElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import 'chartjs-adapter-date-fns';

ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    LineElement,
    BarElement,
    PointElement,
    Title,
    Tooltip,
    Legend
);

// Styled components for layout.
const DashboardContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const ChartSection = styled.section`
  margin-bottom: 40px;
`;

const SectionTitle = styled.h2`
  font-size: 20px;
  margin-bottom: 20px;
`;

const DataGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
`;

const DataCard = styled.div`
  background: #ffffff;
  border: 1px solid #dddddd;
  padding: 20px;
  border-radius: 4px;
`;

// API base URL defined in environment variables.
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

/**
 * Helper function to aggregate events by user and by date.
 * Returns an object where each key is a user_id and its value is an object
 * mapping date strings (YYYY-MM-DD) to the count of events.
 */
const aggregateUserEventsByType = (data, timeField) => {
    const userDateMap = {};
    data.forEach(item => {
        if (!item[timeField]) return;
        let ts = null;
        const timeFieldData = item[timeField];
        if (timeFieldData.$date && timeFieldData.$date.$numberLong) {
            ts = parseInt(timeFieldData.$date.$numberLong, 10);
        } else {
            ts = new Date(timeFieldData).getTime();
        }
        const date = new Date(ts).toISOString().slice(0, 10);
        const user = item.user_id;
        if (!user) return;
        if (!userDateMap[user]) {
            userDateMap[user] = {};
        }
        userDateMap[user][date] = (userDateMap[user][date] || 0) + 1;
    });
    return userDateMap;
};

const Dashboard = () => {
    // State to store fetched data.
    const [prospectingData, setProspectingData] = useState([]);
    const [loginData, setLoginData] = useState([]);
    const [listingData, setListingData] = useState([]);
    const [userInfo, setUserInfo] = useState([]);

    // New state variables for aggregated time-series data.
    const [loginAggregatedData, setLoginAggregatedData] = useState({});
    const [loginChartLabels, setLoginChartLabels] = useState([]);
    const [prospectingAggregatedData, setProspectingAggregatedData] = useState({});
    const [prospectingChartLabels, setProspectingChartLabels] = useState([]);

    // Fetch data on mount.
    useEffect(() => {
        // Prospecting Activity.
        fetch(`${API_BASE_URL}/api/getProspectingActivity`, {
            credentials: 'include',
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })
            .then(res => res.json())
            .then(data => setProspectingData(data.data))
            .catch(err => console.error(err));

        // Login Activity.
        fetch(`${API_BASE_URL}/api/getLoginActivity`, {
            credentials: 'include',
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })
            .then(res => res.json())
            .then(data => setLoginData(data.data))
            .catch(err => console.error(err));

        // Listings Data.
        fetch(`${API_BASE_URL}/api/getUserListings`, {
            credentials: 'include',
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })
            .then(res => res.json())
            .then(data => setListingData(data.data))
            .catch(err => console.error(err));

        // User Information.
        fetch(`${API_BASE_URL}/api/getpeople-prosp`, {
            credentials: 'include',
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })
            .then(res => res.json())
            .then(data => setUserInfo(data.data))
            .catch(err => console.error(err));
    }, []);

    // Map user_id to full name using the fetched user information.
    const userInfoMap = userInfo.reduce((acc, user) => {
        acc[user._id] = `${user.first_name} ${user.last_name}`;
        return acc;
    }, {});

    // Aggregate data for the bar chart (summary by user).
    const prospectingCounts = prospectingData.reduce((acc, activity) => {
        const user = activity.user_id;
        acc[user] = (acc[user] || 0) + 1;
        return acc;
    }, {});

    const loginCounts = loginData.reduce((acc, login) => {
        const user = login.user_id;
        acc[user] = (acc[user] || 0) + 1;
        return acc;
    }, {});

    const listingCounts = listingData.reduce((acc, item) => {
        acc[item.user_id] = item.listingCount;
        return acc;
    }, {});

    // Consolidated list of user IDs from all sources.
    const users = Array.from(
        new Set([
            ...Object.keys(prospectingCounts),
            ...Object.keys(loginCounts),
            ...Object.keys(listingCounts),
        ])
    );

    const barChartData = {
        labels: users.map(userId => userInfoMap[userId] || userId),
        datasets: [
            {
                label: 'Prospecting Activity',
                data: users.map(userId => prospectingCounts[userId] || 0),
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
            },
            {
                label: 'Login Activity',
                data: users.map(userId => loginCounts[userId] || 0),
                backgroundColor: 'rgba(255, 206, 86, 0.5)',
            },
            {
                label: 'Listings Count',
                data: users.map(userId => listingCounts[userId] || 0),
                backgroundColor: 'rgba(75, 192, 192, 0.5)',
            },
        ],
    };

    const barChartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'User Productivity Metrics',
            },
        },
    };

    // -------------------------------
    // Aggregation for Time Series Charts
    // -------------------------------

    // Aggregate login activity by user and date.
    useEffect(() => {
        if (!loginData.length) return;
        const aggregated = aggregateUserEventsByType(loginData, 'login_time');
        setLoginAggregatedData(aggregated);

        const dateSet = new Set();
        Object.values(aggregated).forEach(userDates => {
            Object.keys(userDates).forEach(date => dateSet.add(date));
        });
        const sortedDates = Array.from(dateSet).sort();
        setLoginChartLabels(sortedDates);
    }, [loginData]);

    // Aggregate prospecting activity by user and date.
    useEffect(() => {
        if (!prospectingData.length) return;
        const aggregated = aggregateUserEventsByType(prospectingData, 'timestamp');
        setProspectingAggregatedData(aggregated);

        const dateSet = new Set();
        Object.values(aggregated).forEach(userDates => {
            Object.keys(userDates).forEach(date => dateSet.add(date));
        });
        const sortedDates = Array.from(dateSet).sort();
        setProspectingChartLabels(sortedDates);
    }, [prospectingData]);

    // Prepare datasets for the Login Activities Time Series Chart.
    const loginDatasets = Object.keys(loginAggregatedData).map((userId, index) => {
        const dataForUser = loginAggregatedData[userId];
        const data = loginChartLabels.map(date => ({ x: date, y: dataForUser[date] || 0 }));
        const colors = [
            'rgba(54, 162, 235, 0.7)',
            'rgba(255, 99, 132, 0.7)',
            'rgba(255, 206, 86, 0.7)',
            'rgba(75, 192, 192, 0.7)',
            'rgba(153, 102, 255, 0.7)',
            'rgba(255, 159, 64, 0.7)',
        ];
        return {
            label: userInfoMap[userId] || userId,
            data,
            fill: false,
            borderColor: colors[index % colors.length],
            tension: 0.1,
        };
    });

    // Prepare datasets for the Prospecting Activities Time Series Chart.
    const prospectingDatasets = Object.keys(prospectingAggregatedData).map((userId, index) => {
        const dataForUser = prospectingAggregatedData[userId];
        const data = prospectingChartLabels.map(date => ({ x: date, y: dataForUser[date] || 0 }));
        const colors = [
            'rgba(54, 162, 235, 0.7)',
            'rgba(255, 99, 132, 0.7)',
            'rgba(255, 206, 86, 0.7)',
            'rgba(75, 192, 192, 0.7)',
            'rgba(153, 102, 255, 0.7)',
            'rgba(255, 159, 64, 0.7)',
        ];
        return {
            label: userInfoMap[userId] || userId,
            data,
            fill: false,
            borderColor: colors[index % colors.length],
            tension: 0.1,
        };
    });

    // Configure the Login Activities Time Series Chart.
    const loginChartData = {
        datasets: loginDatasets,
    };

    const loginChartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Login Activities Over Time',
            },
        },
        scales: {
            x: {
                type: 'time',
                time: { unit: 'day' },
                title: { display: true, text: 'Date' },
            },
            y: {
                title: { display: true, text: 'Activity Count' },
            },
        },
    };

    // Configure the Prospecting Activities Time Series Chart.
    const prospectingChartData = {
        datasets: prospectingDatasets,
    };

    const prospectingChartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Prospecting Activities Over Time',
            },
        },
        scales: {
            x: {
                type: 'time',
                time: { unit: 'day' },
                title: { display: true, text: 'Date' },
            },
            y: {
                title: { display: true, text: 'Activity Count' },
            },
        },
    };

    return (
        <AdminTemplate>
            <DashboardContainer>
                {/* Bar Chart: Productivity Metrics by User */}
                <ChartSection>
                    <SectionTitle>User Productivity Overview</SectionTitle>
                    <Bar data={barChartData} options={barChartOptions} />
                </ChartSection>
                
                {/* Time Series Chart: Prospecting Activities */}
                <ChartSection>
                    <SectionTitle>Prospecting Activities Over Time</SectionTitle>
                    <Line data={prospectingChartData} options={prospectingChartOptions} />
                </ChartSection>

                {/* Time Series Chart: Login Activities */}
                <ChartSection>
                    <SectionTitle>Login Activities Over Time</SectionTitle>
                    <Line data={loginChartData} options={loginChartOptions} />
                </ChartSection>
                

                {/* User Details */}
                <SectionTitle>User Details</SectionTitle>
                <DataGrid>
                    {users.map(userId => (
                        <DataCard key={userId}>
                            <h3>
                                {userInfoMap[userId]
                                    ? `User: ${userInfoMap[userId]}`
                                    : `User ID: ${userId}`}
                            </h3>
                            <p>Prospecting Activities: {prospectingCounts[userId] || 0}</p>
                            <p>Login Count: {loginCounts[userId] || 0}</p>
                            <p>Listings: {listingCounts[userId] || 0}</p>
                        </DataCard>
                    ))}
                </DataGrid>
            </DashboardContainer>
        </AdminTemplate>
    );
};

export default Dashboard;
