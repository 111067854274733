import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { LoadingStyle } from "../assets/styledcomponents/StyledComponents.js";

const SideBarTemplate = ({ loadingState, users, otherUser, handleOtherUserChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  // Close dropdown or expanded list if the user clicks outside.
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest("#sidebar-container")) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOpen]);

  const toggleList = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <Container id="sidebar-container">
      <ButtonSideBar onClick={toggleList}>
        Lists {isOpen ? "(See Less)" : "(See More)"}
      </ButtonSideBar>
      {isOpen && (
        <>
          {loadingState ? (
            <LoadingContainer>
              <LoadingStyle style={{ width: "80px", height: "80px", margin: "20px" }} />
            </LoadingContainer>
          ) : (
            <List>
              {users.map((user) => (
                <ListItem
                  key={user._id}
                  onClick={() => handleOtherUserChange(user._id)}
                  isSelected={otherUser === user._id}
                >
                  {user.first_name} {user.last_name}
                </ListItem>
              ))}
            </List>
          )}
        </>
      )}
    </Container>
  );
};

export default SideBarTemplate;

// Styled components used in the sidebar
const ButtonSideBar = styled.button`
  width: 100%;
  font-size: 16px;
  cursor: pointer;
  padding: 12px 16px;
  border: none;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
  background-color: #f5f5f5;
  &:hover {
    background-color: #d6d6d6;
  }
`;

const Container = styled.div`
  width: 162px;
  margin: 0 auto;
`;

const List = styled.ul`
  max-height: 600px;
  overflow-y: scroll;
  width: 178px;
  padding: 0;
  margin: 0;
  border: none;
  list-style: none;
  padding-right: 5px;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
`;

const ListItem = styled.li`
  padding: 12px 16px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
  border: none;
  color: black;
  background-color: ${(props) => (props.isSelected ? "#c7ecfd" : "#f5f5f5")};
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.isSelected ? "#9dc6da" : "#d6d6d6")};
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;
