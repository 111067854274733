// SuperuserRoute.js
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { CurrentUserContext } from './CurrentUserContext.js';

const SuperuserRoute = ({ children }) => {
  const { currentUserState } = useContext(CurrentUserContext);

  // If no user is logged in or the user is not a superuser, redirect.
  if (!currentUserState || currentUserState.user_type !== 'superuser') {
    return <Navigate to="/admin" />;
  }
  return children;
};

export default SuperuserRoute;
